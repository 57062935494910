import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Tab,
  Nav,
  Accordion,
} from "react-bootstrap";
import config from "../../../coreFIles/config";
import E1 from "../images/gallery/E1.png";
import E2 from "../images/gallery/E2.png";
import E3 from "../images/gallery/E3.png";
import E4 from "../images/gallery/E4.png";
import E5 from "../images/gallery/E5.png";
import E6 from "../images/gallery/E6.png";
import E7 from "../images/gallery/E7.png";
import '../css/gallery.css'

import "../css/style.css";
import "../css/responsive.css";

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Zoom } from "react-reveal";

const Gallery = () => {
  return (
    <>
      <div className="gallery">

        <div className="d-flex justify-content-center">
          <ul className="g_ul">
            <li>
              <a>
                <figure>
                  <img src={E1} className="g_img" alt="event"/>
                  <figcaption></figcaption>
                </figure>
              </a>
            </li>
            <li>
              <a>
                <figure>
                  <img src={E2} className="g_img" alt="event"/>
                  <figcaption></figcaption>
                </figure>
              </a>
            </li>
            <li>
              <a>
                <figure>
                  <img src={E3} className="g_img" alt="event" />
                  <figcaption></figcaption>
                </figure>
              </a>
            </li>
            <li>
              <a>
                <figure>
                  <img src={E4} className="g_img" alt="event" />
                  <figcaption></figcaption>
                </figure>
              </a>
            </li>
            <li>
              <a>
                <figure>
                  <img src={E5} className="g_img" alt="event" />
                  <figcaption></figcaption>
                </figure>
              </a>
            </li>
            <li>
              <a>
                <figure>
                  <img src={E6} className="g_img" alt="event" />
                  <figcaption></figcaption>
                </figure>
              </a>
            </li>
            <li>
              <a>
                <figure>
                  <img src={E7} className="g_img" alt="event" />
                  <figcaption></figcaption>
                </figure>
              </a>
            </li>
            <li>
              <a>
                <figure>
                  <img src='images/eventNew/1.webp' alt="event" style={{ objectFit: "contain" }} className="g_img" />
                  <figcaption></figcaption>
                </figure>
              </a>
            </li>
            <li>
              <a>
                <figure>
                  <img src='images/eventNew/2.webp' alt="event" style={{ objectFit: "contain" }} className="g_img" />
                  <figcaption></figcaption>
                </figure>
              </a>
            </li>
            <li>
              <a>
                <figure>
                  <img src='images/eventNew/4.webp' alt="event" style={{ objectFit: "contain" }} className="g_img" />
                  <figcaption></figcaption>
                </figure>
              </a>
            </li>
          </ul>
        </div>


        {/* <Container>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 300: 2, 500: 3, 700: 2, 900: 3 }}
          >
            <Masonry>
              <img src={E3} />
              <img src={E7} />
              <img src={E2} />
              <img src={E6} />
              <img src={E1} />
              <img src={E4} />
              <img src={E5} />
            </Masonry>
          </ResponsiveMasonry>
        </Container> */}



        {/* <Row>
          <Col className="p-0">
            <img src={E1} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E2} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E3} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E4} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E5} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E6} className="img-fluid" />
          </Col>
        </Row>
        <Row>
          <Col className="p-0">
            <img src={E1} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E2} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E3} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E4} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E5} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E6} className="img-fluid" />
          </Col>
        </Row> */}

        {/* <Container>
          <Row>
            <Col lg={6} className="px-1">
              <div className="eventdetailImg">
                <img src={E1} />
              </div>
            </Col>
            <Col lg={6} className="px-1">
              <div className="collapsImg">
                <div className="nft_pic nft_multi">
                  <a className>
                    <img src={E4} />
                  </a>
                  <a className>
                    <img src={E1} />
                  </a>
                  <a className>
                    <img src={E3} />
                  </a>
                  <a className>
                    <img src={E6} />
                  </a>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg={6} className="px-1">
              <div className="collapsImg">
                <div className="nft_pic nft_multi">
                  <a className>
                    <img src={E7} />
                  </a>
                  <a className>
                    <img src={E5} />
                  </a>
                  <a className>
                    <img src={E2} />
                  </a>
                  <a className>
                    <img
                      src={E3}
                      style={{
                        background: "rgb(238, 238, 238)",
                        "object-fit": "contain",
                      }}
                    />
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={6} className="px-1">
              <div className="eventdetailImg">
                <img src={E1} />
              </div>
            </Col>
          </Row>
        </Container> */}
      </div>
    </>
  );
};
export default Gallery;
