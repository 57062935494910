/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import ScrollToTop from "react-scroll-to-top";
import config from "../../../coreFIles/config";
import toast, { Toaster } from "react-hot-toast";
import { Container, Row, Col } from "react-bootstrap";
import Esplogo from "../images/Esplogo.png";
import { Zoom, Slide } from "react-reveal";
import "../css/footer.css";
import { addNewsLetterAction } from "../../../Action/user.action";

const Footer = () => {
  const [email, setEmail] = useState("");
  const inputHandler = (e) => {
    setEmail(e.target.value)
}
  
  const addSubscriberAPI = async (e) => {
    e.preventDefault();
    let res = await addNewsLetterAction({ 'email': email });
    if (res.success) {
        toast.success(res.msg);
        setEmail('')
    } else {
        toast.error(res.msg);
    }
}
  return (
    <>
      <Toaster />

      <footer className="footer footer_bg position-relative">
        <Slide top>
          <div className="footer-scroll ">
            <ScrollToTop className=""/>
          </div>
        </Slide>
        <Container>
          <Row>
            <Col md={12} className="border-top border-dark pt-5">
              <Row>
                <Col md={4} sm={12} className="col-md-4">
                  <div className="footer-logo mb-3">
                    <img alt="" src="assets/images/logo.png" width="200px" />
                  </div>
                  <p className="light-grey">
                  Mr Mint believes in instrumenting and prospecting the world of Web 3.0 so that we can prosper in this Digital Universe. In this Web 3.0 era, Mr Mint is leading the charge, guiding the people towards a decentralized future where possibilities are truly limitless.
                  </p>
                </Col>
                <Col md={8} dm={12} className="footer-link">
                  <Row>
                    <Col md={4} xs={6} className=" mb-2">
                      <h2>Mr Mint</h2>
                      <div className="footer-box">
                        <ul>
                          <li>
                            {/* <a href={`${config.baseUrl}buy`}>Buy Token</a> */}
                            <a href='https://www.coinstore.com/#/spot/MNTUSDT?ts=1712937732921' target="_Blank">Buy Token</a>
                          </li>
                          <li>
                            <a href={`${config.baseUrl}vestingwallet`}>
                              Wallet
                            </a>
                          </li>
                          <li>
                            <a href={`${config.baseUrl}staking`}>Staking</a>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col md={4} xs={6} className=" mb-2">
                      <h2>Support</h2>
                      <div className="footer-box">
                        <ul>
                          <li>
                            <a href="tos">Terms of Use</a>
                          </li>
                          <li>
                            <a href="privacypolicy">Privacy Policy</a>
                          </li>
                          <li>
                            <a href="cookies">Cookie policy</a>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col md={4} className="mb-2">
                      <h2>Resources</h2>
                      <div className="footer-box">
                        <ul>
                          <li>
                            <a href="contact-us">Contact Us</a>
                          </li>
                          {/* <li>
                            <a href={`${config.baseUrl}faq`}>FAQs</a>
                          </li> */}
                          <li>
                            <a href={`${config.baseUrl}blog`}>Blog</a>
                          </li>
                        </ul>
                        <div className="pt-4">
                          <p className="mb-2">Sign up to our Newsletter!</p>
                          <div className="row">
                            <div className="col-md-8 col-8 pr-0">
                              <input
                                id="newsletterEmail"
                                type="email"
                                name="email"
                                placeholder="Enter your email"
                                value={email} onChange={inputHandler} 
                              />
                            </div>
                            <div className="col-md-4 col-4 pl-0">
                              <button className=" btn-submit" onClick={addSubscriberAPI}>Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={12}>
                  <div className="d-flex align-items-center mt-4">
                    <p className="me-2 pb-0">Tech Partner :</p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://espsofttech.com/"
                    >
                      {" "}
                      <img src={Esplogo} />
                    </a>
                  </div>
                  <div className="d-inline-block">
                    <p className="Copyright mt-2">
                      Copyright © 2024 <a href="">mrmint.io</a>
                    </p>
                  </div>
                  <div className="d-inline-block footerRight">
                    <div className="social-icon mb-3">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://t.me/MrMint_Community"
                            rel="noreferrer"
                          >
                            <img src="images/telegram.png" alt="telegram" />
                          </a>
                        </li>
                        {/* <li><a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UC--xdmFcnf_AKlZM6TRQSSQ?sub_confirmation=1"><img src="images/youtube.png" alt='youtube' /></a></li> */}
                        {/* <li><a target="_blank" rel="noreferrer" href="https://mrmintofficial.medium.com/"><img src="images/medium.png" alt='medium' /></a></li> */}
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://discord.gg/VqHnXe2EJ9"
                          >
                            <img src="images/discord.png" alt="discord" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href=" https://www.instagram.com/officialmrmint/"
                          >
                            <img src="images/instagram.png" alt="instagram" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/officialmrmint/"
                          >
                            <img src="images/facebook.png" alt="facebook" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://twitter.com/MrMint_Official"
                          >
                            <img src="images/twiiter.png" alt="twitter" />
                          </a>
                        </li>
                        {/* <li><a target="_blank" href="https://www.linkedin.com/in/mr-mint/" rel="noreferrer"><img src="images/linkedin.png" /></a></li> */}
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.reddit.com/r/MrMint_Official/"
                          >
                            <img src="images/reddit.png" alt="reddit" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* <div
                                        className="d-inline-block pull-right text-right"
                                        style={{ marginTop: 27, fontSize: 15 }}
                                    >
                                        <strong>Contract Address</strong> &nbsp;:&nbsp;
                                        <a rel="noreferrer"
                                            target="_blank"
                                            style={{ color: "#c64eff" }}
                                            href="https://bscscan.com/token/0x3e81Aa8d6813Ec9D7E6ddB4e523fb1601a0e86F3"
                                        >
                                            0x3e81Aa8.......0e86F3
                                        </a>
                                    </div> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
export default Footer;
