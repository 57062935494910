import React from "react";
import { Button, Container } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import config from "../coreFIles/config";

export default function Thankyou() {
    return (
        <>
            <Container style={{ height: '100vh' }}>
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="text-center">
                        <h1 style={{fontSize:'60px'}}>Thank You!</h1>
                        <div className="d-flex justify-content-center my-4">
                            <span className="thankyou_icon">
                                <FaCheck />
                            </span>
                        </div>

                        <p>Your details has been received.</p>

                        <Link to={`${config.baseUrl}`}>
                            <Button variant="primary" className="mt-4">GO HOME</Button>
                        </Link>

                        <hr />
                    </div>
                </div>
            </Container>
        </>
    )
}