import abi from "../component/web3/abi";

const config = {
  TEST_SITE_KEY: process.env.REACT_APP_TEST_SITE_KEY,
  TEST_SITE_KEY_BIZ: process.env.REACT_APP_TEST_SITE_KEY_BIZ,
  baseUrl: process.env.REACT_APP_BASEURL,
  refLink: process.env.REACT_APP_REFLINK,
  refLinkForNFT: process.env.REACT_APP_REFLINKFORNFT,
  serverPath: process.env.REACT_APP_SERVERPATH,
  marketplaceServerPath: process.env.REACT_APP_MARKETPLACESERVERPATH,
  imageUrl: process.env.REACT_APP_IMAGEURL,
  nftFront: process.env.REACT_APP_NFTFRONT,
  nfthomeurl: process.env.REACT_APP_NFTHOMEURL,
  homeurl: process.env.REACT_APP_HOMEURL,
  nftimageUrl: process.env.REACT_APP_NFTIMAGEURL,
  depositAddress: process.env.REACT_APP_DEPOSITADDRESS,// testing'0x2115Bee6aD1feE71ae0E9AC911Db964BfD958833', // nftdeposite(deposit user mnt to admin wallet )


  //old for testnet
  // clientAddress:"0xe1712E0350E8D2A451B82c2a65082D12F82B734C",

 coinstoreurl: process.env.REACT_APP_COINSTOREURL,
  ipfsurl: process.env.REACT_APP_IPFSURL,


  adminAddress: process.env.REACT_APP_ADMINADDRESS, // For Referral
  blockchainUrl: process.env.REACT_APP_BLOCKCHAINURL,
  // chainId:  '0x61',  // Testnet
  chainId: process.env.REACT_APP_CHAINID, // Mainnet,

  contractAddressForRecharge: process.env.REACT_APP_CONTRACTADDRESSFORRECHARGE,//'0xB1Ffd350644dBd1a7eC779f1f816F8EC4f4761F3'
  mrmintContractAbi: abi.mrmintContractAbi,
  chainIdForRecharge: process.env.REACT_APP_CHAINIDFORRECHARGE,
  contractAddress: process.env.REACT_APP_CONTRACTADDRESS,
  abi: abi.abi
}

export default config;


