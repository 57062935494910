import React from 'react'

const Comming_soon = () => {
  return (
    <>
        <section className='comming_soon'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        afaf
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Comming_soon;
